import uni2460 from './svg/uni2460.svg';
import uni2461 from './svg/uni2461.svg';
import uni2462 from './svg/uni2462.svg';
import uni2463 from './svg/uni2463.svg';
import uni2464 from './svg/uni2464.svg';
import uni2465 from './svg/uni2465.svg';
import uni2466 from './svg/uni2466.svg';
import uni2467 from './svg/uni2467.svg';
import uni2468 from './svg/uni2468.svg';
import uni2469 from './svg/uni2469.svg';

import uni2776 from './svg/uni2776.svg';
import uni2777 from './svg/uni2777.svg';
import uni2778 from './svg/uni2778.svg';
import uni2779 from './svg/uni2779.svg';
import uni277A from './svg/uni277A.svg';
import uni277B from './svg/uni277B.svg';
import uni277C from './svg/uni277C.svg';
import uni277D from './svg/uni277D.svg';
import uni277E from './svg/uni277E.svg';
import uni277F from './svg/uni277F.svg';

const SOLID_ICONS = [
  uni2460,
  uni2461,
  uni2462,
  uni2463,
  uni2464,
  uni2465,
  uni2466,
  uni2467,
  uni2468,
  uni2469
];

const OUTLINE_ICONS = [
  uni2776,
  uni2777,
  uni2778,
  uni2779,
  uni277A,
  uni277B,
  uni277C,
  uni277D,
  uni277E,
  uni277F
];

export function WizardStepIcon(props) {
  const { step, highlighted } = props;

  const svgContainer = document.createElement('div');
  svgContainer.innerHTML = highlighted ? SOLID_ICONS[step] : OUTLINE_ICONS[step];

  return svgContainer.querySelector('svg');
}

function StepComponent(props) {
  const { label, step, stepLabel, stepCount, active, highlighted } = props;

  const li =
    <li class="step">
      <span class="step-label">{label}</span>
      <div class="step-content">
        <div class="wizard-line" ></div>
        <WizardStepIcon step={step} highlighted={highlighted} />
        <div class="step-label-mobile"><small>{stepLabel} of {stepCount}</small></div>
      </div>
    </li>;

  if (active) li.classList.add('step-active');
  if (highlighted) li.classList.add('step-highlighted');
  return li;
}

export class WizardSteps {
  static isClassComponent = true;

  constructor({ ref, stepOptions }) {
    if (ref) ref(this);

    this._stepOptions = stepOptions;

    this.root =
      <ul class="wizard-steps list-unstyled"></ul>;
  }

  render(step) {
    this.root.textContent = '';
    const stepCount = this._stepOptions.length;

    this._stepOptions.map((option, key) => {
      const active = key == step;
      const highlighted = key <= step;

      this.root.appendChild(<StepComponent label={option.label} step={key} stepLabel={step + 1} stepCount={stepCount} active={active} highlighted={highlighted} />);
    });
  }
}
