import { SIGNUP_STEPS, SIGNUP_AWAITING_EMAIL, SIGNUP_EMAIL_DUPLICATE_ERROR, SIGNUP_AWAITING_TOKEN, SIGNUP_CHOOSING_DID, SIGNUP_FINALIZE, SIGNUP_FINISHED, SIGNUP_DISABLED } from './SignupController';
import { WizardSteps } from './WizardSteps';

import Alert from 'Components/Alert';
import HookedAlert from 'Components/HookedAlert';
import { RefFormData, FormError, FormStatic, FormText, FormPassword, FormSelect, FormCheckbox } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import LoadingIndicator from 'Components/LoadingIndicator';
import TbIcons from 'Components/TbIcons';
import addShowPasswordToggle from './addPasswordVisibility';

import { getTimezoneOptions } from 'DateTime';
import { Table } from './Tables';

import { ValidatedForm } from './ValidatedForm';

import errors from './errors';

const TZ_OPTS = getTimezoneOptions();

export class SignupPage {
  static isClassComponent = true;

  constructor({ ctrl, ref = null }) {
    if (ref) ref(this);

    const hooks = this.hooks = new Hooks();
    this.ctrl = ctrl;

    const ctx = {
      hooks,
      ctrl,
    };

    this.ctrl.on('update', () => this.render());

    let formContainer;
    this.root =
      <div class="content-wrapper">
        <div class="container">
          <div class="header">
            <img class="logo" src="/img/logo.svg" />
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div use:hook={hooks.hide('state', state => state === SIGNUP_DISABLED)}>
                <WizardSteps stepOptions={SIGNUP_STEPS} ref={this._wizardSteps} />
              </div>
              <div ref={formContainer}>
                <EmailForm ctx={ctx} ref={this._emailForm} />
                <EmailDuplicateError ctx={ctx} />
                <TokenForm ctx={ctx} />
                <DIDsForm ctx={ctx} ref={this._didsForm} />
                <FinalizeForm ctx={ctx} ref={this._finalizeForm} />
                <FinishedForm ctx={ctx} />
                <div use:hook={hooks.show('state', state => state === SIGNUP_DISABLED)}>New account signup is unavailable at this time.</div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                &copy;2024 TelePray. All Rights Reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>;

    this._loading = new LoadingIndicator(formContainer);
  }

  render() {
    const { state, step, loading } = this.ctrl;

    this._loading.toggle(loading);

    switch (state) {
    case SIGNUP_AWAITING_EMAIL:
      this._emailForm.render();
      break;

    case SIGNUP_CHOOSING_DID:
      this._didsForm.render();
      break;

    case SIGNUP_FINALIZE:
      this._finalizeForm.render();
      break;
    }

    this._wizardSteps.render(step);

    this.hooks.run(this.ctrl);
  }
}

// TODO: document.body.appendChild is bad
// potential fix:
//   1. store list of elements that need captcha rendered in a global var
//   2. do window.grecaptcha.render at end of Loader promise chain
function FormCaptchaCheckbox(props) {
  const { form, name, label, sitekey } = props;

  let captchaToken = null;
  let captchaDiv;

  const root =
    <div class="form-group">
      <div class="control-label" />
      <div class="col-sm-8" ref={captchaDiv} />
    </div>;
  document.body.appendChild(root);

  window.grecaptcha.render(captchaDiv, {
    sitekey,
    callback: token => {
      captchaToken = token;
    },
    'expired-callback': () => {
      captchaToken = null;
    },
  });

  form.addField(name, {
    root,
    el: null,
    label,
    getValue() {
      return captchaToken;
    },
    setValue() {
    },
  });

  return root;
}

class EmailForm extends ValidatedForm {
  static isClassComponent = true;

  constructor({ ctx: { hooks, ctrl }, ref }) {
    super();

    this.ctrl = ctrl;
    ref(this);

    this.root =
      <div class="form-horizontal" use:hook={hooks.show('state', state => state === SIGNUP_AWAITING_EMAIL)}>
        <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
        <FormError icon={false} ref={this._formError} />
        <p>
          Each TelePray account requires a unique email address. (There is a maximum of one account per email address.)
          <br />
          <br />
          Please make sure that you have immediate access to your email to receive the activation link.
        </p>

        <FormText form={this} name="email" label="Enter Email Address" />

        <FormCaptchaCheckbox form={this} name="recaptchaToken" label="reCaptcha Checkbox" sitekey={ctrl.config.RECAPTCHA_SITE_KEY} />
        <p class="mt-5">
          I already have an email address registered with TelePray. <a href={ctrl.config.LOGIN_URL}>Take me to the login page.</a>
        </p>
        <div class="btn-toolbar-discrete wizard-nav">
          <button type="button" class="btn btn-primary ml-auto" onclick={() => this.submit()}>Next</button>
        </div>
      </div>;

    this.setRules({
      email: {
        required: true,
        email: true,
      },
      recaptchaToken: {
        required: true,
      },
    });
  }

  render() {
    this._formError.render(false);
  }

  submit() {
    if (!this.validate()) {
      this._formError.render(this.errors);
      return;
    }
    this.ctrl.startEmailVerify(this.getAllValues());
  }
}

function EmailDuplicateError({ ctx: { hooks, ctrl } }) {
  return (
    <div class="form-horizontal" use:hook={hooks.show('state', state => state === SIGNUP_EMAIL_DUPLICATE_ERROR)}>
      <div class="mb-5">
        <Alert>A TelePray account already exists for <span use:hook={hooks.text('email')} /></Alert>
      </div>

      <div class="btn-toolbar-discrete wizard-nav">
        <button type="button" class="btn btn-primary" onclick={() => ctrl.backToEmailVerify()}>Back</button>
        <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.redirectToLCM()}>Take me to the Login Page</button>
      </div>
    </div>
  );
}

class TokenForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctx: { hooks, ctrl } }) {
    super();

    this.ctrl = ctrl;

    this.root =
      <div class="form-horizontal" use:hook={hooks.show('state', state => state === SIGNUP_AWAITING_TOKEN)}>
        <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
        <p>
          An activation link has been sent to <strong use:hook={hooks.text('email')} /><br />
          Please click on the activation link in that email to continue.
        </p>
        <p>Alternatively, enter your Activation Code:</p>
        <FormText form={this} name="token" />
        <p class="mt-5">
          If you did not receive the activation email, check your spam folder. To request a new activation code, <a href="signup.html">click here</a>.
        </p>
        <p>
          If you can't find the activation email, please contact us at <a href="mailto:support@telepray.com">support@telepray.com</a>
        </p>
        <div class="btn-toolbar-discrete wizard-nav">
          <button type="button" class="btn btn-primary ml-auto" onclick={() => this.submit()}>Next</button>
        </div>
      </div>;
  }

  submit() {
    const token = this.get('token');
    this.ctrl.setToken(token);
  }
}

class DIDsTable extends Table {
  constructor({ ref }) {
    super({
      ref,
      className: 'nowrap dids-table',
      thead: false,
      rowKey: 'mapID',
      columns: [
        {
          colKey: ['phoneNumberFormatted', 'isSelected'],
          create(cell) {
            return (
              <div class={`did-item ${cell.isSelected ? 'did-selected' : ''}`}>
                <span class="tbicon">{ cell.isSelected ? TbIcons.CHECKBOX_CHECKED : TbIcons.CHECKBOX_UNCHECKED }</span>
                { cell.phoneNumberFormatted }
              </div>
            );
          },
        },
      ],
    });
  }
}

class DIDsForm {
  static isClassComponent = true;

  constructor({ ctx: { hooks, ctrl }, ref }) {
    this.ctrl = ctrl;
    ref(this);

    this.root =
      <div use:hook={hooks.show('state', state => state === SIGNUP_CHOOSING_DID)}>
        <p>
          Your TelePray account includes a dedicated dial in number so that your participants do not have to enter an access code to join your prayer line. Please choose your dial in number from the options below:
        </p>
        <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
        <div class="panel panel-primary did-panel mt-5">
          <div class="panel-heading">
            <h3 class="panel-title">Dial In Numbers</h3>
          </div>
          <div class="panel-body">
            <DIDsTable ref={this._didsTable} />
            <div class="btn-toolbar-discrete did-paging-btn-container">
              <button type="button" class="btn btn-link" use:hook={hooks.show('didHasNextPage')} onclick={() => ctrl.nextDIDs()} ref={this._nextPageBtn}>Show More Numbers</button>
            </div>
          </div>
        </div>
        <p class="mt-5">
          Our inventory of dial in numbers is limited, and you do not have the option of selecting your area code. You must choose one of the dial in numbers displayed on this page to activate your TelePray account.
        </p>
        <div class="btn-toolbar-discrete wizard-nav">
          <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.completeDIDSelection()}>Next</button>
        </div>
      </div>;

    this._didsTable.root.addEventListener('click', (e) => {
      const { target } = e;
      const mapID = this._didsTable.getKeyByElement(target);
      ctrl.selectDID(mapID);
    });
  }

  render() {
    const { dids } = this.ctrl;

    this._didsTable.render(dids);
  }
}

class FinalizeForm extends ValidatedForm {
  static isClassComponent = true;

  constructor({ ctx: { hooks, ctrl }, ref }) {
    super();

    this.ctrl = ctrl;
    ref(this);

    this.root =
      <div use:hook={hooks.show('state', state => state === SIGNUP_FINALIZE)}>
        <div class="form-horizontal">
          <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
          <FormError icon={false} ref={this._formError} />
          <FormStatic form={this} name="didPhoneNumber" label="Dial In Number" />
          <FormStatic form={this} name="email" label="Email Address" />
          <FormPassword form={this} inputAttributes={{autocomplete: 'new-password'}} name="password" label="Password" />
          <FormPassword form={this} inputAttributes={{autocomplete: 'new-password'}} name="passwordConfirm" label="Re-enter Password" />
          <FormText form={this} name="administratorName" label="Your Name" />
          <FormText form={this} name="accountName" label="Description" comment="Optional (Program, organization, etc.)"/>
          <FormText form={this} name="phoneNumber" label="Mobile Number" inputAttributes={{placeholder: 'XXX-XXX-XXXX'}} comment="Optional (US & Canada Only)" />
          <FormSelect form={this} name="timezone" options={TZ_OPTS} label="Time Zone" />
          <FormCheckbox rootClass="mt-3" form={this} name="enableReports"
            labelRight="Send me post-conference email reports" />
          <FormCheckbox rootClass="mt-3" form={this} name="tos"
            labelRight={<>I Agree to the <a href={ctrl.config.TERMS_OF_SERVICE_URL} target="_blank">TERMS OF SERVICE</a></>} />

        </div>
        <div class="btn-toolbar-discrete wizard-nav mt-5">
          <button type="button" class="btn btn-primary" onclick={() => ctrl.unselectDID()}>Back</button>
          <button type="button" class="btn btn-primary ml-auto" onclick={() => this.submit()}>Signup</button>
        </div>
        <p class="mt-5">
          <span>OUR PROMISE: </span>
          None of your personal information (including your email address and phone number) will be used for marketing purposes, nor shared or sold to any other organization for that purpose. Your personal information will only be used for servicing your account.
        </p>
      </div>;

    addShowPasswordToggle(this, 'password');
    addShowPasswordToggle(this, 'passwordConfirm');

    this.set('timezone', ctrl.config.DEFAULT_TIMEZONE);

    this.setRules({
      administratorName: {
        required: true,
      },
      password: {
        required: true,
        rangelength: [6, 64],
      },
      passwordConfirm: {
        validateFunction: value => {
          if (!value.length) {
            return 'required';
          }

          if (this.checkField('password') !== true) {
            return 'required';
          }

          if (value === this.get('password')) {
            return true;
          }

          return 'invalid';
        },
        errorMessage: 'Passwords do not match',
      },
      tos: {
        required : true,
        errorMessage: 'You must agree to the terms of service',
      },
    });
  }

  render() {
    const { selectedDID } = this.ctrl;

    this._formError.render(false);

    const { phoneNumberFormatted } = selectedDID;

    this.set('didPhoneNumber', phoneNumberFormatted);
    this.set('email', this.ctrl.email);
  }

  submit() {
    if (!this.validate()) {
      this._formError.render(this.errors);
      return;
    }
    this.ctrl.signupDID(this.getAllValues());
  }
}

function FinishedForm({ ctx: { hooks, ctrl } }) {
  return (
    <div use:hook={hooks.show('state', state => state === SIGNUP_FINISHED)}>
      <p>Thank you for joining TelePray! Your new prayer line is now active.</p>
      <FinishedFormTable ctx={{ hooks, ctrl }} />
      <div class="btn-toolbar-discrete wizard-nav">
        <button type="button" class="btn btn-primary" onclick={() => ctrl.redirectToLCM()}>Go to Account Dashboard</button>
      </div>
    </div>
  );
}

function FinishedFormTable({ ctx: { hooks: hooksOuter, ctrl } }) {
  const hooks = new Hooks();
  hooksOuter.add('signupData', signupData => {
    if (signupData)
      hooks.run(signupData);
  });

  return (
    <>
      <table class="table-plain">
        <tbody>
          <tr>
            <td>Dial-in Number</td>
            <td><strong use:hook={hooks.text('dialInNumber')}/></td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td use:hook={hooks.text('email')}></td>
          </tr>
          <tr>
            <td>Your Name</td>
            <td use:hook={hooks.text('administratorName')}></td>
          </tr>
          <tr>
            <td>Description</td>
            <td use:hook={hooks.text('accountName')}></td>
          </tr>
        </tbody>
      </table>

      <div class="panel panel-primary mt-5">
        <div class="panel-heading">
          <h3 class="panel-title">Conference Settings</h3>
        </div>
        <div class="panel-body">
          <table class="conference-settings">
            <tbody>
              <tr>
                <td>Greeting</td>
                <td>Welcome to TelePray!</td>
              </tr>
              {/* TODO WHAT IS AUTOMATIC HOST
              <tr>
                <td>Automatic Host</td>
                <td></td>
              </tr> */}
              <tr>
                <td>Optional Host PIN</td>
                <td use:hook={hooks.text('pin')}></td>
              </tr>
              <tr>
                <td>Conference Start</td>
                <td use:hook={hooks.text('conferenceStart')}></td>
              </tr>
              <tr>
                <td>Conference Mode</td>
                <td use:hook={hooks.text('conferenceMode')}></td>
              </tr>
              <tr>
                <td>Entry Alerts</td>
                <td use:hook={hooks.text('entryAlerts')}></td>
              </tr>
              <tr>
                <td>Exit Alerts</td>
                <td use:hook={hooks.text('exitAlerts')}></td>
              </tr>
              <tr>
                <td>Record Conferences</td>
                <td use:hook={hooks.text('recordConferences')}></td>
              </tr>
              <tr>
                <td>Send Reports</td>
                <td use:hook={hooks.text('enableReports')}></td>
              </tr>
              <tr>
                <td class="p-1" colspan="2">
                  <p>
                    To customize your prayer line, go to the <strong>Settings</strong> tab in your Account Dashboard.
                    <span use:hook={hooks.show('phoneNumber')}> When calling from <strong><span use:hook={hooks.text('phoneNumber')}/></strong>, you will automatically join your conference as a host. You can change this setting in the Caller List tab.</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
