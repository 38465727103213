import EventEmitter from 'events';

import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';

import createQueryString from 'Browser/createQueryString';
import Location from 'Browser/Location';
import { clamp } from 'MathUtil';

import appErrorHandler from 'appErrorHandler';
import { getTrackingData } from './TrackingData';

export const SIGNUP_AWAITING_EMAIL = 0;
export const SIGNUP_EMAIL_DUPLICATE_ERROR = 1;
export const SIGNUP_AWAITING_TOKEN = 2;
export const SIGNUP_CHOOSING_DID = 3;
export const SIGNUP_FINALIZE = 4;
export const SIGNUP_FINISHED = 5;
export const SIGNUP_DISABLED = 6;

const DIDS_RESULT_COUNT = 6;

export const SIGNUP_STEPS = [
  { label: 'Enter Email Address' },
  { label: 'Validate Email' },
  { label: 'Choose Dial In Number' },
  { label: 'Enter Account Info' },
  { label: 'Activate Account' },
];

const STATE_STEP_MAP = [
  0, // 0 SIGNUP_AWAITING_EMAIL
  1, // 1 SIGNUP_EMAIL_DUPLICATE_ERROR
  1, // 2 SIGNUP_AWAITING_TOKEN
  2, // 3 SIGNUP_CHOOSING_DID
  3, // 4 SIGNUP_FINALIZE
  4, // 5 SIGNUP_FINISHED
];

const CONF_MODE_MAP = {
  conversation: 'Conversation - All participants are un-muted.',
  qa: 'Q&A - Participants start muted (except Hosts), but can un-mute themselves.',
  presentation: 'Presentation - Participants are muted (except Hosts), and cannot un-mute themselves.',
  hostsOnly: 'Private Host - Hosts speak privately. Other participants are placed on hold.',
};

const CONF_START_MAP = {
  instant: 'When second caller joins',
  hostJoins: 'When host joins',
  hostConfirms: 'When host confirms',
};

const CONF_ALERTS_MAP = {
  none: 'No alerts',
  chime: 'Chime',
  name: 'Caller name',
};

const getYesNo = val => val ? 'Yes' : 'No';

export class SignupController extends EventEmitter {
  constructor(config) {
    super();

    this._state = null;
    this._email = null;
    this.config = config;
    this._loading = false;
    this._errorCode = null;

    this._token = null;
    this._dids = null;
    this._didsPaging = new PagingState(DIDS_RESULT_COUNT);

    this._selectedDID = null;
    this._signupData = null;
  }

  _beginOperation() {
    this._loading = true;
    this._errorCode = null;
    this.emit('update');
  }

  _endOperation() {
    this._loading = false;
    this.emit('update');
  }

  init(initialState) {
    this._state = initialState;

    if (this.config.DISABLE_SIGNUP) {
      this._state = SIGNUP_DISABLED;
    }

    if (this._state === SIGNUP_AWAITING_TOKEN) {
      if (Location.query.token) {
        this.setToken(Location.query.token);
        return;
      }
    }

    this.emit('update');
  }

  startEmailVerify(data) {
    if (this._state !== SIGNUP_AWAITING_EMAIL)
      throw new Error('invalid state');

    this._beginOperation();

    const {
      email,
      recaptchaToken,
    } = data;

    const trackingData = getTrackingData();

    const params = {
      email,
      recaptchaToken,
      trackingData,
    };

    this._email = email;

    Api.get('Account', 'startEmailVerify', params, {}, 'authNone')
      .then(data => {
        this._state = SIGNUP_AWAITING_TOKEN;
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'email')
            this._errorCode = 'ERR_INVALID_EMAIL';
        }
        if (!this._errorCode)
          this._errorCode = appErrorHandler(err);

        switch (this._errorCode) {
        case 'ERR_API_SIGNUP_DISABLED':
          this._state = SIGNUP_DISABLED;
          break;
        case 'ERR_API_DUPLICATE_ADMIN_EMAIL':
          this._state = SIGNUP_EMAIL_DUPLICATE_ERROR;
          break;
        }

        window.grecaptcha.reset();
      })
      .then(() => {
        this._endOperation();
      });
  }

  backToEmailVerify() {
    this._beginOperation();
    this._state = SIGNUP_AWAITING_EMAIL;
    this._endOperation();
  }

  setToken(token) {
    if (this._state !== SIGNUP_AWAITING_TOKEN)
      throw new Error('invalid state');

    this._token = token;
    this._dids = [];
    this._reserveDIDs();
  }

  _reserveDIDs() {
    this._beginOperation();

    const { resultCount, startOffset } = this._didsPaging;
    const token = this._token;

    const params = {
      token,
      resultCount,
      startOffset,
    };

    Api.get('Account', 'reserveDIDs', params, {}, 'authNone')
      .then(data => {
        this._state = SIGNUP_CHOOSING_DID;

        this._email = data.email;

        data.DID.forEach(did => {
          did.isSelected = false;
          this._dids.push(did);
        });

        this._didsPaging.update(resultCount, data.totalResults);
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'token')
            this._errorCode = 'ERR_INVALID_TOKEN';
        }
        if (!this._errorCode)
          this._errorCode = appErrorHandler(err);

        switch (this._errorCode) {
        case 'ERR_API_EMAIL_VERIFICATION_EXPIRED':
          this._state = SIGNUP_AWAITING_EMAIL;
          break;
        case 'ERR_API_SIGNUP_DISABLED':
          this._state = SIGNUP_DISABLED;
          break;
        }
      })
      .then(() => {
        this._endOperation();
      });
  }

  nextDIDs() {
    if (this._state !== SIGNUP_CHOOSING_DID)
      throw new Error('invalid state');

    const newPage = this._didsPaging.nextPage;
    if (newPage === this._didsPaging.page) return;
    this._didsPaging.page = newPage;

    this._reserveDIDs();
  }

  selectDID(mapID) {
    if (this._selectedMapID === mapID) {
      return;
    }

    let selectedDID;

    this._dids.forEach(did => {
      did.isSelected = did.mapID === mapID;
      if (did.isSelected)
        selectedDID = did;
    });

    if (!selectedDID) {
      return;
    }

    this._selectedMapID = mapID;
    this._selectedDID = selectedDID;
    this.emit('update');
  }

  completeDIDSelection() {
    if (this._state !== SIGNUP_CHOOSING_DID)
      throw new Error('invalid state');

    if (!this._selectedDID) {
      this._selectedDID = this.dids[Math.floor(Math.random() * this.dids.length)];
    }

    this._state = SIGNUP_FINALIZE;
    this._errorCode = null;

    this.emit('update');
  }

  unselectDID() {
    if (this._state !== SIGNUP_FINALIZE)
      throw new Error('invalid state');

    this._state = SIGNUP_CHOOSING_DID;
    this._selectedDID = null;

    this._endOperation();
  }

  signupDID(data) {
    if (this._state !== SIGNUP_FINALIZE)
      throw new Error('invalid state');

    const {
      password,
      timezone,
      administratorName,
      accountName,
      phoneNumber,
      enableReports,
    } = data;

    const params = {
      token: this._token,
      mapID: this._selectedDID.mapID,
      password,
      timezone,
      administratorName,
      accountName,
      phoneNumber,
      enableReports,
    };

    this._beginOperation();

    Api.get('Account', 'signupDID', params, {}, 'authNone')
      .then(res => {
        this._state = SIGNUP_FINISHED;

        const { email, administratorName, bridge, phoneNumber, authToken } = res.signupDIDResult;

        this._signupData = {
          authToken,
          dialInNumber: bridge.tollNumberFormatted,
          email,
          administratorName,
          accountName: bridge.accountName,
          phoneNumber: phoneNumber,
          enableReports: getYesNo(bridge.enableReports),
          pin: bridge.pin,
          conferenceStart: CONF_START_MAP[bridge.confStart],
          conferenceMode: CONF_MODE_MAP[bridge.confMode],
          entryAlerts: CONF_ALERTS_MAP[bridge.entryChimes],
          exitAlerts: CONF_ALERTS_MAP[bridge.exitChimes],
          recordConferences: getYesNo(bridge.recordCalls),
        };
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'phoneNumber')
            this._errorCode = 'ERR_INVALID_PHONE_NUMBER';
        }
        if (!this._errorCode)
          this._errorCode = appErrorHandler(err);

        switch (this._errorCode) {
        case 'ERR_API_EMAIL_VERIFICATION_EXPIRED':
          this._state = SIGNUP_AWAITING_EMAIL;
          break;
        case 'ERR_API_SIGNUP_DISABLED':
          this._state = SIGNUP_DISABLED;
          break;
        }
      })
      .then(() => {
        this._endOperation();
      });
  }

  redirectToLCM() {
    const authToken = this._signupData && this._signupData.authToken;
    let url = this.config.LOGIN_URL;

    if (authToken)
      url = `${url}?${createQueryString({ authToken })}`;

    window.open(url, '_blank');
  }

  get state() {
    return this._state;
  }

  get step() {
    return STATE_STEP_MAP[this._state];
  }

  get loading() {
    return this._loading;
  }

  get errorCode() {
    return this._errorCode;
  }

  get token() {
    return this._token;
  }

  get email() {
    return this._email;
  }

  get dids() {
    return this._dids;
  }

  get selectedDID() {
    return this._selectedDID;
  }

  get didHasPreviousPage() {
    return this._didsPaging.page > 1;
  }

  get didHasNextPage() {
    return this._didsPaging.page < this._didsPaging.totalPages;
  }

  get signupData() {
    return this._signupData;
  }
}

class PagingState {
  constructor(resultCount) {
    this.page = 1;
    this.resultCount = resultCount;
  }

  update(resultCount, totalResults) {
    this.resultCount = resultCount;
    this._totalResults = totalResults;
  }

  get totalPages() {
    return Math.ceil(this._totalResults / this.resultCount);
  }

  get prevPage() {
    return clamp(this.page - 1, 1, this.totalPages);
  }

  get nextPage() {
    return clamp(this.page + 1, 1, this.totalPages);
  }

  get startOffset() {
    return this.resultCount * (this.page - 1);
  }
}
