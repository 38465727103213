import { closestUntil, getChildNodeIndex } from 'Components/domHelpers';
import { Cond, generateUniqueId } from 'Components/FormComponents';
import reconcile from './reconcile';

export class Table {
  static isClassComponent = true;

  constructor({ rowKey, columns, className, thead = true, tfoot = false, onCellButtonClick = null, onHeaderClick = null, onTrCreated = null, ref }) {
    if (ref) {
      ref(this);
    }

    this._tableId = generateUniqueId();
    this._rowKey = rowKey;
    this._columns = columns;
    this._initState();

    this._onTrCreated = onTrCreated;

    this._dynamicVisibility = [];
    this._dynamicTitles = [];

    const styleElement = <style />;
    document.head.appendChild(styleElement);
    this._styleSheet = styleElement.sheet;

    const colHeaders = columns.map((col, idx) => {
      const th = <th>{col.title}</th>;
      if (col.className)
        th.className = col.className;
      if (col.thClassName)
        th.classList.add(col.thClassName);

      if (col.id)
        this._colIdToIdx.set(col.id, idx);

      if ('visibility' in col) {
        if (!(col.visibility instanceof Function))
          throw new TypeError('invalid visibility');

        this._dynamicVisibility.push(idx);
      }

      if ('titleRender' in col) {
        if (!(col.titleRender instanceof Function))
          throw new TypeError('invalid titleRender');

        this._dynamicTitles.push(idx);
      }

      if (!('visible' in col))
        col.visible = true;

      const nidx = idx + 1;
      this._styleSheet.insertRule(`#${this._tableId} th:nth-child(${nidx}), #${this._tableId} td:nth-child(${nidx}) {${col.visible ? '' : 'display: none;'}}`, idx);

      return th;
    });

    this.root =
      <table id={this._tableId} class={className}>
        <Cond test={thead}>
          <thead>
            <tr ref={this._headTr}>
              {colHeaders}
            </tr>
          </thead>
        </Cond>
        <tbody ref={this._tbody}>
        </tbody>
        <Cond test={tfoot}>
          <tfoot ref={this._tfoot}>
          </tfoot>
        </Cond>
      </table>;

    if (onCellButtonClick) {
      this._tbody.addEventListener('click', e => {
        const { target } = e;
        const button = closestUntil(target, this._tbody, 'button');

        if (!button) return;

        const { key, rowIdx } = this._getNodeInfo(target);
        const colId = this._getColIdByElement(target);

        onCellButtonClick({
          button,
          key,
          rowIdx,
          colId,
        });
      });
    }

    if (onHeaderClick) {
      this._headTr.addEventListener('click', e => {
        const th = closestUntil(e.target, this._headTr, 'th');
        if (!th) return;

        const idx = getChildNodeIndex(th);
        const colDef = this._columns[idx];

        if (!colDef.headerClickable) return;

        onHeaderClick({
          idx,
          origEvent: e,
        });
      });
    }
  }

  _initState() {
    this._prevKeys = [];
    this._prevMap = new Map();
    this._prevExtra = {};
    this._colIdToIdx = new Map();
    this._nodeInfo = new Map();
  }

  render(data, extra = {}) {
    if (!Array.isArray(data))
      throw new TypeError('data must be array');

    this.setVisibility(extra);
    this.setTitles(extra);

    const newMap = new Map();
    const newKeys = data.map(row => row[this._rowKey]);
    const newNodeInfo = new Map();

    reconcile(
      this._tbody,
      this._prevKeys,
      newKeys,
      (rowIdx, key) => {
        const { newRow, tds } = this._createRow(key, data[rowIdx], extra);
        newMap.set(key, newRow);

        const node = <tr data-key={key}>{tds}</tr>;

        newNodeInfo.set(node, {
          key,
          rowIdx,
        });

        if (this._onTrCreated) this._onTrCreated(node, rowIdx);
        return node;
      },
      (node, rowIdx, key) => {
        newNodeInfo.set(node, {
          key,
          rowIdx,
        });

        const prevRow = this._prevMap.get(key);
        const newRow = this._updateRow(node, key, prevRow, data[rowIdx], extra);
        newMap.set(key, newRow);
      },
    );

    this._prevKeys = newKeys;
    this._prevMap = newMap;
    this._prevExtra = extra;
    this._nodeInfo = newNodeInfo;
  }

  _createRow(key, row, extra) {
    const newRow = [];

    const tds = this._columns.map(col => {
      const { colKey, extraKey = null, create } = col;
      let val = null;
      let extraVal = null;

      if (colKey) {
        if (Array.isArray(colKey)) {
          val = {};
          colKey.forEach(prop => val[prop] = row[prop]);
        } else {
          val = row[colKey];
        }
        if (extraKey) {
          if (Array.isArray(extraKey)) {
            extraVal = {};
            extraKey.forEach(prop => extraVal[prop] = extra[prop]);
          } else {
            extraVal = extra[extraKey];
          }
        }
      }

      newRow.push(val);

      const content = create
        ? create(val, extraVal, key)
        : val;
      const td = <td>{content}</td>;
      if (col.className)
        td.className = col.className;

      return td;
    });

    return {
      newRow,
      tds,
    };
  }

  _updateRow(node, key, prevRow, row, extra) {
    const newRow = [];

    this._columns.forEach((col, idx) => {
      const { colKey, extraKey = null, create } = col;

      const prevVal = prevRow[idx];
      let changed = false;
      let val = null;
      let extraVal = null;

      if (colKey) {
        if (Array.isArray(colKey)) {
          const newVal = {};
          colKey.forEach(prop => {
            const partVal = row[prop];
            newVal[prop] = partVal;
            if (partVal !== prevVal[prop]) {
              changed = true;
            }
          });
          if (changed) {
            val = newVal;
          } else {
            val = prevVal;
          }
        } else {
          val = row[colKey];
          if (val !== prevVal) {
            changed = true;
          }
        }
        if (extraKey) {
          if (Array.isArray(extraKey)) {
            extraVal = {};
            extraKey.forEach(prop => {
              const partVal = extra[prop];
              extraVal[prop] = partVal;
              if (partVal !== this._prevExtra[prop]) {
                changed = true;
              }
            });
          } else {
            extraVal = extra[extraKey];
            if (extraVal !== this._prevExtra[extraKey]) {
              changed = true;
            }
          }
        }
      }

      newRow.push(val);

      if (!changed) {
        return;
      }

      const content = create
        ? create(val, extraVal, key)
        : val;
      const td = node.children.item(idx);
      if (typeof content === 'string') {
        td.textContent = content;
      } else if (Array.isArray(content)) {
        td.textContent = '';
        td.append(...content);
      } else {
        td.textContent = '';
        td.append(content);
      }
    });

    return newRow;
  }

  clear() {
    this._tbody.textContent = '';
    this._initState();
  }

  getColumnIdx(colId) {
    const idx = typeof colId === 'number'
      ? colId
      : this._colIdToIdx.get(colId);

    if (this._columns[idx])
      return idx;

    return null;
  }

  getColumnDef(colId) {
    const idx = this.getColumnIdx(colId);
    if (idx === null)
      throw new TypeError(`unknown column ${colId}`);

    return this._columns[idx];
  }

  getKeyByElement(el) {
    const { key } = this._getNodeInfo(el);
    return key;
  }

  _getNodeInfo(el) {
    const node = closestUntil(el, this._tbody, 'tr');
    if (!node)
      throw new TypeError('unknown element');

    const info = this._nodeInfo.get(node);
    if (!info)
      throw new TypeError('unknown element');

    return info;
  }

  _getColIdByElement(el) {
    const td = el.closest('th, td');
    const idx = getChildNodeIndex(td);
    return this._columns[idx].id;
  }

  _getTh(idx) {
    return this._headTr.children.item(idx);
  }

  setVisibility(extra) {
    const len = this._dynamicVisibility.length;
    for (let i = 0; i < len; i++) {
      const idx = this._dynamicVisibility[i];
      const colDef = this._columns[idx];
      this.setColumnVisibility(idx, colDef.visibility(extra));
    }
  }

  setColumnVisibility(colId, visible) {
    const idx = this.getColumnIdx(colId);
    if (idx === null) return;

    const newVisible = !!visible;
    const col = this._columns[idx];
    if (col.visible === newVisible) {
      return;
    }

    col.visible = newVisible;

    const { style } = this._styleSheet.cssRules[idx];
    if (newVisible)
      style.removeProperty('display');
    else
      style.setProperty('display', 'none');
  }

  setTitles(extra) {
    const len = this._dynamicTitles.length;
    for (let i = 0; i < len; i++) {
      const idx = this._dynamicTitles[i];
      const colDef = this._columns[idx];
      this.setTitle(idx, colDef.titleRender(extra));
    }
  }

  setTitle(colId, title) {
    const idx = this.getColumnIdx(colId);
    if (idx === null) return;

    const th = this._getTh(idx);
    th.textContent = title;
  }
}

export class SortableTable extends Table {
  constructor({ columns, ...rest }) {
    columns.forEach(col => {
      if (col.sorting) {
        col.thClassName = 'sorting';
        col.headerClickable = true;
      }
    });

    super({ columns, ...rest });
  }

  renderSort(sortProps) {
    this._columns.forEach((col, idx) => {
      if (!col.sorting) return;

      const sortProp = sortProps.find(prop => prop.idx === idx);

      this
        ._getTh(idx)
        .setAttribute('data-sortorder', sortProp ? sortProp.order : '');
    });
  }
}

export class Sorter {
  static SORT_COMPARE_TYPE_DEFAULT = 0;
  static SORT_COMPARE_TYPE_LOCALE = 1;

  constructor({ table, sortMultiple = false, sortProps = [] }) {
    this._table = table;
    this._sortMultiple = sortMultiple;
    this.setSortProps(sortProps);
  }

  setSortProps(sortProps) {
    this._sortProps = sortProps.map(sortProp => {
      let { colId = null, idx = null, order = 'asc', opts = null } = sortProp;

      if (colId && idx === null) {
        idx = this._table.getColumnIdx(colId);
        if (idx === null)
          throw new TypeError(`invalid colId ${colId}`);
      }

      if (idx !== null) {
        const colDef = this._table.getColumnDef(idx);
        if (!colId)
          colId = colDef.id || null;
        if (!opts)
          opts = colDef.sorting;
      }

      return {
        colId,
        idx,
        order,
        opts,
      };
    });
  }

  getSortProps() {
    return this._sortProps;
  }

  onHeaderClick(e) {
    const flip = order => order === 'asc' ? 'desc' : 'asc';

    const { idx, origEvent } = e;
    const multi = this._sortMultiple && origEvent.shiftKey;

    if (!multi) {
      let order;
      if (this._sortProps.length === 1 && this._sortProps[0].idx === idx) {
        order = flip(this._sortProps[0].order);
      } else {
        order = 'asc';
      }

      this._sortProps = [
        {
          idx,
          order,
        }
      ];
    } else {
      const sortProp = this._sortProps.find(prop => prop.idx === idx);
      if (sortProp) {
        // column is in current sortProps, flip direction
        sortProp.order = flip(sortProp.order);
      } else {
        // column is not in current sortProps, add it
        this._sortProps.push({
          idx,
          order: 'asc',
        });
      }
    }

    this.setSortProps(this._sortProps);
  }

  static compareItems(sortProps, a, b) {
    const compareDefault = (a, b, descend, nullUndefInfinity = false) => {
      if (nullUndefInfinity && (a === null || a === undefined))
        a = Infinity;
      if (nullUndefInfinity && (b === null || b === undefined))
        b = Infinity;

      if (a === b)
        return 0;

      if (descend) {
        if (a > b)
          return -1;
        return 1;
      }

      if (a < b)
        return -1;
      return 1;
    };

    const compareLocale = (a, b, descend, locales = undefined) => {
      if (a === null || a === undefined)
        a = '';
      if (b === null || b === undefined)
        b = '';
      if (typeof a !== 'string')
        a = a.toString();
      if (typeof b !== 'string')
        b = b.toString();

      if (a === b)
        return 0;

      // NB: browsers can legally return any integer, not just -1, 0, 1
      const ret = a.localeCompare(b, locales);

      if (ret > 0)
        return descend ? -1 : 1;
      if (ret < 0)
        return descend ? 1 : -1;

      return 0;
    };

    let ret;
    for (let i = 0; i < sortProps.length; i++) {
      const { order, opts } = sortProps[i];

      const { prop, compareType = Sorter.SORT_COMPARE_TYPE_DEFAULT, reverse = false, nullUndefInfinity = false, locales = undefined } = opts;

      let descend = order !== 'asc';
      if (reverse) descend = !descend;

      const aVal = a[prop];
      const bVal = b[prop];

      switch (compareType) {
      case Sorter.SORT_COMPARE_TYPE_DEFAULT:
        ret = compareDefault(aVal, bVal, descend, nullUndefInfinity);
        break;

      case Sorter.SORT_COMPARE_TYPE_LOCALE:
        ret = compareLocale(aVal, bVal, descend, locales);
        break;
      }

      if (ret !== 0)
        break;
    }
    return ret;
  }
}
