import C from './Constants';

import Api from 'Api/Api';

import validateEmail from 'Util/validateEmail';

const contactFields = [
  'fullName', 'conferenceID', 'email', 'phone', 'comments',
];

function setInvalid(el, msg) {
  const msgEl = el.parentNode.querySelector('.invalid-tooltip');
  msgEl.textContent = msg;
  el.classList.add('is-invalid');
}

function clearInvalid(el) {
  el.classList.remove('is-invalid');
}

class ContactForm {
  constructor(reportType, reportTitle) {
    this._reportType = reportType;
    this._reportTitle = reportTitle;

    this._button = document.querySelector('.btn-contact');
    if (!this._button) {
      return;
    }

    this._alert = document.querySelector('.alert-contact');

    this._button.onclick = () => this._onclick();
  }

  _onclick() {
    const form = document.forms.contactForm;

    Array.from(form.elements).forEach(el => clearInvalid(el));

    let isValid = true;
    const report = {
      appVersion: window.loaderConfig.appVersion,
      requestType: 'contact',
    };

    contactFields.forEach(_ => report[_] = form[_].value.trim());

    if (!report.fullName.length) {
      setInvalid(form.fullName, C.REQUIRED_FIELD);
      isValid = false;
    }

    if (!report.email.length) {
      setInvalid(form.email, C.REQUIRED_FIELD);
      isValid = false;
    } else if (!validateEmail(report.email)) {
      setInvalid(form.email, C.INVALID_FORMAT);
      isValid = false;
    }

    if (!report.phone.length) {
      setInvalid(form.phone, C.REQUIRED_FIELD);
      isValid = false;
    }

    if (!report.comments.length) {
      setInvalid(form.comments, C.REQUIRED_FIELD);
      isValid = false;
    }

    if (!isValid) {
      this._displayError(C.INVALID_FIELDS);
      return;
    }

    const params = {
      type: this._reportType,
      errorString: this._reportTitle,
      errorJSON: JSON.stringify(report),
    };

    Api.get('ErrorReport', 'clientErrorReport', params, {}, 'authNone')
      .then(() => this._showConfirmation())
      .catch(err => {
        this._displayError(C.UNKNOWN_ERR_MSG);
      });
  }

  _displayError(msg) {
    document.querySelector('.contact-form-message p').setAttribute('hidden', '');
    this._alert.removeAttribute('hidden');
    this._alert.textContent = msg;
  }

  _showConfirmation() {
    document.querySelector('.contact-form-view').style.visibility = 'hidden';
    document.querySelector('.contact-form-success').removeAttribute('hidden');
  }
}

export default function initContact(reportType, reportTitle) {
  const contactForm = new ContactForm(reportType, reportTitle);
  return contactForm;
}
