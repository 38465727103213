import localStorage from 'Browser/localStorage';
import debugFactory from 'debug';
import parseQueryString from 'Browser/parseQueryString';
import { LoadError } from 'CommonExceptions';
import { emptyNode } from 'Components/domHelpers';
import { executeGoogleTagManager } from 'Google';
import { configure as configureLogger } from 'Log/logger';

import globalErrorHandlers from 'Error/globalErrorHandlers';

import LoaderStrings, { initStrings } from 'LoaderStrings';

const CSS_STYLE_PROPS_SIMPLE = [
  'backgroundColor',
  'color',
  'borderColor',
  'borderTopColor',
  'borderRightColor',
  'borderBottomColor',
  'borderLeftColor',
  'outlineColor',
];

const CSS_STYLE_PROPS_COMPOUND = [
  'boxShadow',
  'textShadow',
];

var siteConfig = null;
var loaderConfig = window.loaderConfig;

var curLogoPath = null;

if (window.loaderConfig.logConfig)
  configureLogger(window.loaderConfig.logConfig);

// Enable debug() logging if debug is in hash params
if (window.location.hash.length) {
  const q = parseQueryString(window.location.hash.substr(1));
  if ((q.debug || q.debug === '') && !localStorage.debug) {
    debugFactory.enable(loaderConfig.logConfig.consoleFilter || '*');
  }
}

export default class Loader {
  static load() {
    // abort the load if an error has already been displayed
    if (window.preLoader.loadErrorOccurred) {
      const e = new Error('cancelled');
      e.cancelled = true;
      return Promise.reject(e);
    }

    var tasks = [
      this._onDocReady(),
      this._waitForSiteConfig(),
    ];

    window.preLoader.removeErrorHandlers();

    return Promise.all(tasks)
      .then(() => this._checkHttps())
      .then(() => this._initStrings())
      .then(() => this._verifyDomComplete())
      .then(() => this._configApp())
      .then(() => this._setCustomizations())
      .then(() => this.setLogo())
      .then(() => siteConfig);
  }

  static loadError(err) {
    if (err.cancelled) {
      return;
    }

    let message = LoaderStrings.Loader.ERR_LOAD_UNKNOWN;
    if (err instanceof LoadError) {
      message = err.message;
    }

    document.body.appendChild(
      <div class="load-error">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">{LoaderStrings.Loader.ErrorMessage.title}</h3>
          </div>
          <div class="panel-body">
            <p>{message}</p>
            <button type="button" class="btn btn-primary" onclick="window.location.reload();">{LoaderStrings.Loader.ErrorMessage.reload}</button>
          </div>
        </div>
      </div>
    );

    throw err;
  }

  static _onDocReady() {
    if (document.readyState === 'complete') {
      // window load event has fired already
      return;
    }

    return new Promise(resolve => window.addEventListener('load', resolve));
  }

  static _waitForSiteConfig() {
    if (window.preLoader.siteConfig) {
      // siteconfig has loaded already
      siteConfig = window.preLoader.siteConfig;
      return;
    }

    return new Promise(resolve => {
      window.preLoader.onSiteConfig = () => {
        siteConfig = window.preLoader.siteConfig;
        resolve();
      };
    });
  }

  static _verifyDomComplete() {
    const testContainer = document.querySelector('.loader-test');
    if (!testContainer) {
      throw new LoadError(LoaderStrings.Loader.ERR_LOAD_INCOMPLETE);
    }

    const cs = window.getComputedStyle(testContainer);
    if (cs.textAlign !== 'right') {
      throw new LoadError(LoaderStrings.Loader.ERR_LOAD_INCOMPLETE);
    }

    testContainer.parentNode.removeChild(testContainer);
  }

  static _checkHttps() {
    if (siteConfig && siteConfig.settings && siteConfig.settings.FORCE_HTTPS_REDIRECT && window.location.protocol !== 'https:') {
      return new Promise((resolve, reject) => { // never resolves
        const url = 'https:' + window.location.href.substring(window.location.protocol.length);
        window.open(url, '_self');
      });
    }
  }

  static _initStrings() {
    if (!loaderConfig.baseLanguage)
      return;

    const { language: siteLanguage = null } = siteConfig;

    let stringOverrides = null;
    const stringOverridesStr = siteConfig.settings && siteConfig.settings.stringOverrides;
    if (stringOverridesStr) {
      try {
        stringOverrides = JSON.parse(stringOverridesStr);
      } catch (e) {
      }
    }

    let stringReplacements = null;
    const stringReplacementsStr = siteConfig.settings && siteConfig.settings.stringReplacements;
    if (stringReplacementsStr) {
      try {
        stringReplacements = JSON.parse(stringReplacementsStr);
      } catch (e) {
        throw new LoadError('stringReplacements invalid JSON');
      }
      if (!Array.isArray(stringReplacements))
        throw new LoadError('stringReplacements must be array');
      stringReplacements.forEach((repl, idx) => {
        if (!(typeof repl.old === 'string' && typeof repl.new === 'string'))
          throw new LoadError(`stringReplacements[${idx}] missing valid 'old' or 'new'`);
      });
    }

    initStrings(siteLanguage, stringOverrides, stringReplacements);
  }

  static _configApp() {
    if (siteConfig.API_URL) {
      globalErrorHandlers.configure(siteConfig.API_URL, siteConfig.API_TIMEOUT);
    }
  }

  static _setCustomizations() {
    const siteSettings = siteConfig.settings || {};
    const { faviconURL, documentTitle, cssColorMap, cssVars } = siteSettings;

    if (faviconURL) {
      const oldLink = document.querySelector('link[rel="icon"]');
      if (oldLink) {
        oldLink.remove();
      }

      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = faviconURL;

      document.head.appendChild(link);
    }

    if (documentTitle) {
      document.title = documentTitle;
    }

    if (cssColorMap) {
      this._changeCssColors(cssColorMap);
    }

    if (cssVars) {
      this._setCssVars(cssVars);
    }
  }

  static _changeCssColors(mapJson) {
    let mapHex;
    try {
      mapHex = JSON.parse(mapJson);
    } catch (e) {
      throw new Error('invalid cssColorMap JSON');
    }

    const hexToRgb = val => {
      if (val.length !== 7) {
        throw new Error('invalid cssColorMap color format');
      }

      const r = parseInt(val.substr(1, 2), 16);
      const g = parseInt(val.substr(3, 2), 16);
      const b = parseInt(val.substr(5, 2), 16);

      return `rgb(${r}, ${g}, ${b})`;
    };

    const map = [];
    Object.entries(mapHex).forEach(([ from, to ]) => {
      map.push([ hexToRgb(from), hexToRgb(to) ]);
    });

    const ss = document.getElementById('style-main').sheet;

    for (let i = 0; i < ss.cssRules.length; i++) {
      const { style } = ss.cssRules[i];
      if (!style) {
        continue;
      }

      CSS_STYLE_PROPS_SIMPLE.forEach(prop => {
        const val = style[prop];
        if (!val) {
          return;
        }
        for (let j = 0; j < map.length; j++) {
          const pair = map[j];
          if (val === pair[0]) {
            style[prop] = pair[1];
            break;
          }
        }
      });

      CSS_STYLE_PROPS_COMPOUND.forEach(prop => {
        const val = style[prop];
        if (!val) {
          return;
        }
        for (let j = 0; j < map.length; j++) {
          const pair = map[j];
          style[prop] = val.replaceAll(pair[0], pair[1]);
        }
      });
    }
  }

  static _setCssVars(cssVarsJson) {
    let cssVars;
    try {
      cssVars = JSON.parse(cssVarsJson);
    } catch (e) {
      throw new Error('invalid cssVars JSON');
    }

    const root = document.documentElement;

    Object.entries(cssVars).forEach(([ name, value ]) => {
      root.style.setProperty(name, value);
    });
  }

  static get logoPath() {
    const { logoPathConfigOption }  = loaderConfig;

    if (logoPathConfigOption && siteConfig.settings && siteConfig.settings[logoPathConfigOption]) {
      return siteConfig.settings[logoPathConfigOption];
    }

    return siteConfig && siteConfig.logoPath;
  }

  static setLogo(logoPath) {
    if (!loaderConfig.logoContainer) {
      return;
    }

    // take logo from siteConfig if unspecified
    if (!logoPath) {
      logoPath = this.logoPath;
    }

    if (!logoPath) {
      return;
    }

    // logo already set, abort
    if (logoPath === curLogoPath) {
      return;
    }

    const img = document.createElement('img');
    img.setAttribute('alt', 'Logo');
    img.setAttribute('class', 'logo img-responsive');
    img.setAttribute('src', logoPath);

    const { logoWidthConfigOption }  = loaderConfig;
    if (logoWidthConfigOption && siteConfig && siteConfig.settings && siteConfig.settings[logoWidthConfigOption])
      img.setAttribute('width', siteConfig.settings[logoWidthConfigOption]);

    const logoContainers = document.querySelectorAll(loaderConfig.logoContainer);
    if (!logoContainers.length) {
      return;
    }

    curLogoPath = logoPath;

    logoContainers.forEach(node => {
      emptyNode(node);
      node.appendChild(img.cloneNode(true));
    });

    if (img.complete) {
      return;
    }

    return new Promise((resolve, reject) => {
      img.addEventListener('load', resolve);
      img.addEventListener('error', resolve);
    });
  }

  static loadComplete() {
    if (siteConfig && siteConfig.settings && siteConfig.settings.GOOGLE_TAG_MANAGER_ID) {
      setTimeout(
        () =>
          executeGoogleTagManager(siteConfig.settings.GOOGLE_TAG_MANAGER_ID)
        , 50
      );
    }

    if (siteConfig && siteConfig.settings && siteConfig.settings.CLICKY_IMAGE_URL) {
      setTimeout(() => {
        const img = document.createElement('img');

        img.src = siteConfig.settings.CLICKY_IMAGE_URL;
        img.alt = '';
        img.height = img.width = 1;
        img.style.display = 'none';

        document.body.appendChild(img);
      }, 50);
    }

    return new Promise((resolve, reject) => {
      window.preLoader.loadingComplete(resolve);
    });
  }

  static get config() {
    return loaderConfig;
  }
}

if (loaderConfig.appName) {
  globalErrorHandlers.setAppName(loaderConfig.appName);
}

if (loaderConfig.errorReportURL) {
  globalErrorHandlers.configure(loaderConfig.errorReportURL);
}
