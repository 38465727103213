export default function addShowPasswordToggle(form, fieldName) {
  const input = form.getInput(fieldName);
  input.after(
    <span class="toggle-password form-control-feedback" onclick={() => toggleElement(input)} />
  );
}

function toggleElement(el) {
  el.type = el.type === 'password' ? 'text' : 'password';
}
