import { ApiErrorNetwork, ApiErrorResult } from 'Api/ApiErrors';
import report from 'Error/report';
import logger from 'Log/logger';

import errors from './errors';

const log = logger('appErrorHandler');

/**
 * Gets an internal errorCode for an Error. Sends an error report for
 * unrecognized errors.
 *
 * @param {any} err
 * @returns {string} internal errorCode
 */

export default function appErrorHandler(err) {
  log('handling', err);

  let errorCode;
  if (err instanceof ApiErrorNetwork) {
    errorCode = 'ERR_NETWORK';
  } else if (err instanceof ApiErrorResult) {
    const { code } = err;
    errorCode = code in errors
      ? code
      : 'ERR_UNKNOWN';
  } else {
    errorCode = 'ERR_UNKNOWN';

    setTimeout(() => {
      report.send('appErrorHandler', null, err);
    }, 0);
  }

  return errorCode;
}
