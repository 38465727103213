import { SignupController } from './SignupController';
import { SignupPage } from './SignupPage';

export default function main(config, initialState) {
  const ctrl = new SignupController(config);
  document.body.appendChild(
    <SignupPage ctrl={ctrl} />
  );
  ctrl.init(initialState);
}
