export default function initNavbar() {
  const { body } = document;
  const sideMenu = document.querySelector('.side-menu');
  const backdrop = document.createElement('div');
  const button = document.querySelector('.navbar .navbar-toggler');

  if (!button) {
    return;
  }

  backdrop.setAttribute('class', 'navbar-backdrop show');

  let windowWidth = window.innerWidth;

  function openMenu() {
    sideMenu.classList.add('in');

    body.appendChild(backdrop);
  }

  function closeMenu() {
    if (!sideMenu.classList.contains('in'))
      return;

    sideMenu.classList.remove('in');
    backdrop.remove();
  }

  button.onclick = () => {
    if (sideMenu.classList.contains('in')) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  backdrop.onclick = closeMenu;

  window.onresize = () => {
    const newWidth = window.innerWidth;
    if (newWidth == windowWidth) {
      return;
    }

    windowWidth = newWidth;

    closeMenu();
  };
}
