import ClientStorage from 'Browser/ClientStorage';

const CLIENT_STORAGE_KEY = 'trackingData';

const TRACKING_PARAMS = [
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_adgroupid',
  'utm_matchtype',
  'utm_device',
  'utm_devicemodel',
  'utm_placement',
  'utm_target',
  'gclid',
  'gbraid',
  'wbraid',
];

export function storeTrackingData() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const data = {};

  TRACKING_PARAMS.forEach(param => {
    const paramValue = urlParams.get(param);

    if (paramValue) {
      data[param] = paramValue;
    }
  });

  if (Object.keys(data).length) {
    ClientStorage.writeJSON(CLIENT_STORAGE_KEY, data);
  }
}

export function getTrackingData() {
  return ClientStorage.readJSON(CLIENT_STORAGE_KEY);
}
