import 'shims';

import Loader from 'Loader';

import Api from 'Api/Api';
import ApiContextAuthNone from 'Api/ApiContextAuthNone';
import initNavbar from 'Components/navbar';

import signupMain from './signupMain';
import { SIGNUP_AWAITING_TOKEN, SIGNUP_AWAITING_EMAIL } from './SignupController';

import initContact from './contact';
import { storeTrackingData } from './TrackingData';

const CONTACT_REPORT_TYPE = 'support-telepray';
const CONTACT_REPORT_TITLE = 'Contact Form - TelePray';

function staticMain() {
  initNavbar();
  initContact(CONTACT_REPORT_TYPE, CONTACT_REPORT_TITLE);
}

let _resolveCaptchaLoad;

function loadRecaptcha(src) {
  return new Promise((resolve, reject) => {
    _resolveCaptchaLoad = resolve;

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  });
}

window.onloadCallback = () => {
  _resolveCaptchaLoad();
};

const { entryPointMainModuleName } = Loader.config;

let mainFunction;

switch (entryPointMainModuleName) {
case 'activateMain':
  mainFunction = config => signupMain(config, SIGNUP_AWAITING_TOKEN);
  break;

case 'signupMain':
  mainFunction = config => signupMain(config, SIGNUP_AWAITING_EMAIL);
  break;

default:
  mainFunction = staticMain;
  break;
}

let config;

storeTrackingData();

Loader.load()
  .then(siteConfig => {
    const { API_URL } = siteConfig;
    if (!API_URL) {
      throw new Error('invalid siteConfig');
    }

    const {
      disableSignup: DISABLE_SIGNUP,
    } = siteConfig;

    const {
      LOGIN_URL,
      DEFAULT_TIMEZONE,
      RECAPTCHA_URL,
      RECAPTCHA_SITE_KEY,
    } = siteConfig.settings;

    const {
      TERMS_OF_SERVICE_URL,
    } = Loader.config.siteSettings;

    config = {
      DISABLE_SIGNUP,
      LOGIN_URL,
      TERMS_OF_SERVICE_URL,
      DEFAULT_TIMEZONE,
      RECAPTCHA_URL,
      RECAPTCHA_SITE_KEY,
    };

    Api.addContext(new ApiContextAuthNone('authNone', {
      API_URL,
    }));

    return loadRecaptcha(`${config.RECAPTCHA_URL}?onload=onloadCallback&render=explicit`);
  })
  .then(() => mainFunction(config))
  .then(() => Loader.loadComplete())
  .catch(err => Loader.loadError(err));
